import debounce from 'just-debounce-it'
import React, {useContext} from 'react'
import {Col, Form, FormControl, Row} from 'react-bootstrap'
import Select, {SingleValue} from 'react-select'
import {useUnidades} from '../../../shared/hooks/useUnidades'
import {AltaContext} from './AltaContext'
import {Status} from './types/GenTypes'

type SingleValueOption = {value: Status; label: string}

const statusOptions: SingleValueOption[] = [
  {value: 'pendiente', label: 'Pendiente'},
  {value: 'observado', label: 'Observado'},
  {value: 'enviado', label: 'Enviado'},
  {value: 'alta', label: 'En alta'},
]

const AltaHeader: React.FC = () => {
  const {
    procesos,
    setProcessId,
    setUnidadId,
    processId,
    setFieldSelected,
    setSheetId,
    status,
    setStatus,
    searchValue,
    setSearchValue,
  } = useContext(AltaContext)
  const {toOptions} = useUnidades()

  const handleChangeProcess = (e: any) => {
    setProcessId(e.value)
    // setFieldSelected({})
    // setSheetId('')
  }

  const handleChangeUnidad = (e: any) => {
    setUnidadId(e.value)
  }

  const defaultProcess = procesos.find((pr) => pr.value === processId)

  const handleChangeStatus = (newValue: SingleValue<SingleValueOption>): void => {
    setStatus((newValue?.value) ?? 'pendiente')
    // setFieldSelected({})
    // setSheetId('')
  }

  const onChangeSearch = debounce((value: string) => {
    setSearchValue(value)
  }, 500)

  return (
    <section>
      <Row>
        <Col>
          <Form.Group>
            <FormControl
              type='text'
              id='search'
              placeholder='Buscar por nombres, apellidos o Nro. de documento'
              onChange={(e) => onChangeSearch(e.target.value)}
              // value={searchValue}
            />
          </Form.Group>
        </Col>
        <Col>
          <Select
            options={toOptions()}
            placeholder='Seleccione una unidad'
            onChange={handleChangeUnidad}
          />
        </Col>
        <Col>
          <Select
            options={procesos}
            value={defaultProcess}
            placeholder='Seleccione un proceso'
            onChange={handleChangeProcess}
          />
        </Col>
        <Col>
          <Select
            options={statusOptions}
            value={statusOptions.find((x) => x.value === status) ?? statusOptions[0]}
            onChange={handleChangeStatus}
          />
        </Col>
      </Row>
    </section>
  )
}

export {AltaHeader}
