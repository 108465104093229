import React, { useContext, useState, useEffect, FC } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { CollapsibleSectionItem } from './CollapsibleSectionItem'
import { Campos, ProcesoFichas, Seccion } from '../../../../../documentacion/MiFicha/FIchaInterface'
import { Section, Sheet } from '../../../../fichas/Sheet'
import { PartnerContext, SectionMigration, SheetMigration, UpdateFieldMigration } from '../../../PartnerContexts'


const grid = 3

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#c7fff0' : '#EFEFEF',
  padding: grid,
  width: '100%',
  borderRadius: 10,
})

interface MyComponentProps {
  sheet: Sheet;
  ind: number;
  index: number;
  onUpdate: (updatedData: any) => void;
}


const DraggableSectionList: FC<MyComponentProps> = (props) => {
  const { sheet, ind,index, onUpdate } = props
  const {updateFieldMigration,setUpdateFieldMigration} = useContext(PartnerContext)

  const [loading, setLoading] = useState(true)
  const [formDataFichaSeccion, setFormDataFicha] = useState(sheet)

  useEffect(() => {
    if(sheet && sheet.sections){
      if(sheet.sections?.length>0 ){
        handleChangeSheet()
      }      
    }
    
  }, [sheet])

  
  useEffect(() => {
    if(formDataFichaSeccion.sections && formDataFichaSeccion.sections.length>0){
      handleAddSectionMigration()      
    }
    
  }, [formDataFichaSeccion])


  const handleChangeSheet = () => {
    const filteredFichas : any ={
      ...sheet,
      sections: sheet.sections?.map((sections:Section) => ({
        ...sections,
        fields: sections.fields?.filter((campo) => campo.checkIntegracion)
      }))
      .filter((sections) => sections.fields && sections.fields.length > 0)
    } 
    /* console.log("filteredFichas")
    console.log(filteredFichas) */
    setFormDataFicha(filteredFichas)
    setLoading(false)

  }

  const handleAddSectionMigration = () => {
    if(formDataFichaSeccion.sections){
      const filteredSection : SectionMigration[] = formDataFichaSeccion.sections.map(sec => ({
        id_seccion:sec.id,
        name:sec.name,
        campos: sec.fields && sec.fields.length > 0
          ? sec.fields.map(campo => ({
              id_campo: campo.id,
              name: campo.name,
              tagIntegracion: campo.tagIntegracion || ''
            }))
          : []
      }))

      
      if(filteredSection.length > 0){
        const newFicha:SheetMigration[] = [...updateFieldMigration.fichas]
        if (index >= 0 && index < newFicha.length) {
          newFicha[index] = {
            ...newFicha[index],
            seccion: filteredSection // Aquí asignas filteredSection correctamente
          };
          setUpdateFieldMigration({...updateFieldMigration,fichas: newFicha})
        }
        
      }
    }
    

  }

  const handleUpdateSeccion = (ind: number, updatedData: Section) => {
    onUpdate(updatedData);
    const filteredSection : SectionMigration = {
      id_seccion:updatedData.id,
      name:updatedData.name,
      campos: updatedData.fields && updatedData.fields.length > 0
        ? updatedData.fields.map(campo => ({
            id_campo: campo.id,
            name: campo.name,
            tagIntegracion: campo.tagIntegracion || ''
          }))
        : []
    }
    
    const updateSeccion:SectionMigration[] = [...updateFieldMigration.fichas[index].seccion]
    updateSeccion[ind] = filteredSection

    const newFicha:SheetMigration[] = [...updateFieldMigration.fichas]
    if (index >= 0 && index < newFicha.length) {
      newFicha[index] = {
        ...newFicha[index],
        seccion: updateSeccion // Aquí asignas filteredSection correctamente
      };
      setUpdateFieldMigration({...updateFieldMigration,fichas: newFicha})
    }
    
  };


  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {loading? (
        <div>Loading...</div>
      ) : (
        <Droppable droppableId={`droppable` + ind}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {(formDataFichaSeccion.sections || []).map((item: Section, index) => (
                <CollapsibleSectionItem
                  key={"section"+index}
                  ind={ind}
                  item={item}
                  index={index}
                  onUpdate={(updatedData) => handleUpdateSeccion(index, updatedData)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  )
}

export { DraggableSectionList }
