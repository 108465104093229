import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button, Col} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useDropzone} from 'react-dropzone'
import { PartnerContext } from '../../../PartnerContexts'
import * as excelJs from 'exceljs';
import ExcelJS from 'exceljs'
import { useGlobalOverlayLoading } from '../../../../../../shared/hooks/useGlobalOverlayLoading';
import { formatDateToDMY } from "../../../../../../helpers/dateFunctions";
import { getExcelLetter } from '../../../../../generales_functions/excel'
import { showInfoHtml, showMessage } from '../../../../../../shared/Alerts'
import { addFieldExternalData } from '../../../../../../services/external'


type Columns = {NameGrupo: string; NameCampo: string[],Value: any[]}[]

const ShowExportarPlantillaModalForm = () => {
  const {externalData, selectGroupName, setReloadServiceExternal, setShowExportPlantillaExcel} = useContext(PartnerContext)
  const [file, setFile] = useState<File | null>(null)
  const [cargandoData, setCargandoData] = useState(false)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()

  useEffect(() => {
    readFile()
  }, [file])

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0] || null)
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'application/xlsx': ['.xlsx'],
    },
    maxFiles: 1,
  })

  const readFile = async () => {
    if (file ) {
      setCargandoData(true)
      const datErroneo: {celda: string; value: string}[] = []
      let LabelGrupo = ""
      const arrayHeader: string[] = []
      const arrayObjectCampos: any[] = []
      const workbook = new ExcelJS.Workbook()
      const reader = new FileReader()

      reader.onload = async (e: any) => {
        const arrayBuffer = e.target.result
        await workbook.xlsx.load(arrayBuffer)
        // Supongamos que el archivo Excel tiene una sola hoja
        const worksheet = workbook.getWorksheet(1)
        // Accede a los datos de la hoja y procesa según tus necesidades
        worksheet.eachRow((row, rowNumber) => {
          const obj: {[key: string]: string} = {}
          const lastColumnNumber = row.cellCount
          row.eachCell((cell, colNumber) => {
            let exist = false
            if (rowNumber === 1) {
              LabelGrupo = cell.text
            }
            if (rowNumber === 2) {
              arrayHeader.push(cell.text)
            }
            const isLastCell = colNumber === lastColumnNumber
            // Validando Campos
            if (rowNumber > 2) {
              
              obj[arrayHeader[colNumber - 1]] = cell.text
            }
          })
          if (rowNumber > 2) {
            arrayObjectCampos.push(obj)
          }
        })

        if (datErroneo.length > 0) {
          setCargandoData(false)
          let codigoHTML = ''
          codigoHTML += `Se encontraron campos que no son admisibles, Verificar los campos que sean correctos <br/>
                              <div class="centrar-tabla" >
                              <table border="1">
                              <thead>
                                  <tr>
                                    <th>Celda</th>
                                    <th>Campos</th>
                                  </tr>
                              </thead>
                              <tbody> `
          for (const dat of datErroneo) {
            codigoHTML += '<tr><td>' + dat.celda + '</td><td>' + dat.value + '</td></tr>'
          }
          codigoHTML += '</tbody> </table> </div>'
          showInfoHtml('info', 'Campos no admisible', codigoHTML)
        } else {
          const option = {
                          fields:{label:LabelGrupo,fields:arrayObjectCampos},
                          after_field:"",
                          equal_field:selectGroupName
                        }
          // console.log(option)
          await addFieldExternalData(
              externalData._id,
              option
            )
            .then((response) => {
              if (response.success) {
                showMessage('success', 'Registro Correctamente', response.message)
              } else {
                showMessage('error', 'Hubo un Error', response.message)
              }
              setCargandoData(false)
              setReloadServiceExternal(true)
            })
            .catch((error) => {
              console.log(error)
              setCargandoData(false)
            })
            
            setShowExportPlantillaExcel(false)
        }
      }
      reader.readAsArrayBuffer(file)
    }
    
  }

  const handleExport = () => {
    
  const newExternalDataFields = externalData.fields.filter(field => String(field.label) === String(selectGroupName));

    const columns : Columns = []
    let NameCampoArray: string[] = []
    const Value: any[] = []
    let obj : any=  {}
    newExternalDataFields[0].fields.map((fields:any) => {
      NameCampoArray = []
      obj={}
      Object.keys(fields).map(key => {
        NameCampoArray.push(key)
        obj[key] = fields[key] 
      })
      Value.push(obj)
    })
    columns.push({NameGrupo:newExternalDataFields[0].label,NameCampo:NameCampoArray,Value:Value})
    exportExcelJS(columns)
  }

  const exportExcelJS = async (data: Columns) => {
    const workbook = new excelJs.Workbook()
    const worksheet = workbook.addWorksheet()

    worksheet.mergeCells(`A1:${getExcelLetter(data[0].NameCampo.length)}1`);
    const groupCell = worksheet.getCell('A1');
    groupCell.value = data[0].NameGrupo;
    groupCell.font = {
        name: 'Calibri',
        size: 14,
        bold: true,
        color: { argb: 'FFFFFF' },
    };
    groupCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0A58CA' },
        bgColor: { argb: '' },
    };
    groupCell.alignment = { vertical: 'middle', horizontal: 'center' };

    // Adding Header Row
    const header = data[0].NameCampo;
    const headerKeys = data[0].NameCampo.map((campo:any) => ({key:campo}))
    const value = data[0].Value;
    const headerRow = worksheet.addRow(header);
    // const contentRow = worksheet.addRow(value);
    worksheet.columns = headerKeys;
    headerRow.eachCell((cell) => {
    cell.alignment = {
        horizontal: 'center',
    };
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DCE0F7' },
        bgColor: { argb: '' },
    };
    cell.font = {
        color: { argb: '45494E' },
        size: 12,
    };
    });
    value.map((dataRow) => {
      const row = worksheet.addRow(dataRow);
      row.eachCell({ includeEmpty: true },(cell) => {
        if (cell.value) {
          cell.alignment = {
              horizontal: 'center',
          };
          cell.font = {
              color: { argb: '45494E' },
              size: 12,
          };
          if(cell.value === "date"){
              cell.value = formatDateToDMY(new Date());
              cell.numFmt = 'dd/mm/yyyy';
          }
        }
        
      });

    })
    
    
    if (worksheet.columns) {
    worksheet.columns.forEach(function (column) {
        if (column) {
        let dataMax = 0;
        column.eachCell && column.eachCell({ includeEmpty: true }, function (cell, idx) {
            const columnLength = cell.value ? String(cell.value).length : 0;
            if (columnLength > dataMax) {
            dataMax = columnLength;
            }
        });
        if (column) {
            column.width = dataMax < 15 ? 15 : dataMax;
        }
        }
    });
    }

    worksheet.addRows([{}, {}, {}]);
    
    const rowToLock = worksheet.getRow(5);
    rowToLock.eachCell((cell) => {
    cell.protection = {
        locked: true,
    };
    });




    workbook.xlsx.writeBuffer()
    .then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'mi_archivo_excel.xlsx';
    a.click();
    })
    .finally(() => setGlobalOverlayLoading(false))
  }

  return (
    <>
      <Form >
        <Row className='mb-3'>
          <Col className='d-flex justify-content-center align-items-center' >
            <a 
              className='btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'
              onClick={handleExport} >
              Exportar Plantilla Excel a Modificar
            </a>
          </Col>
          <Col sm={12} className='mt-5'>
              <div className=''>
                {
                  <div
                    {...getRootProps()}
                    className={`elevator-with-shadow dropzone ${isDragActive ? 'active' : ''}`}
                  >
                    <input {...getInputProps()} />
                    <i className='bi bi-cloud-upload fs-4x'></i>
                    {isDragActive ? (
                      <p>Arrastra y suelta los archivos aquí el excel a modificar...</p>
                    ) : (
                      <p>Arrastra archivos aquí o haz clic para seleccionar</p>
                    )}
                    <a className='btn btn-sm btn-outline btn-outline-dashed btn-outline-info btn-active-light-info'>
                      Seleccionar
                    </a>
                  </div>
                }
              </div>
            </Col>
        </Row>
      </Form>
    </>
  )
}

export {ShowExportarPlantillaModalForm}
