import Swal from 'sweetalert2'
import { validarCodigo } from '../services/authenticacion'

const showInfoCopy = (icon: 'success' | 'info' | 'error' | 'warning', title: string, text: string) => {
  return Swal.fire({
    icon,
    title,
    text,
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    toast: true,
    position: 'top-end',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    customClass: {
      popup: 'bg-white border rounded p-4',
      title: 'text-dark mb-2',
      closeButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showInfoHtml = (icon: 'success' | 'info' | 'error', title: string, html: string) => {
  return Swal.fire({
    icon,
    title,
    html: html,
    showConfirmButton: false,
    /* timer: 2000,
    timerProgressBar: true,
    toast: true, */
    position: 'top-end',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    customClass: {
      popup: 'bg-white border rounded p-4',
      title: 'text-dark mb-2',
      closeButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showInfoHtmlCenter = (icon: 'success' | 'info' | 'error', title: string, html: string) => {
  return Swal.fire({
    icon,
    title,
    html: html,
    showConfirmButton: false,
    width: 800,
    position: 'center',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    customClass: {
      popup: 'bg-white border rounded p-4',
      title: 'text-dark mb-2',
      closeButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showMessage = (icon: 'success' | 'error' | 'info', title: string, text: string) => {
  return Swal.fire({
    icon,
    title,
    text,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    toast: true,
    position: 'top-end',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    customClass: {
      popup: 'bg-white border rounded p-4',
      title: 'text-dark mb-2',
      closeButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showDeleteMessage = () => {
  return Swal.fire({
    icon: 'warning',
    title: '¿Estas seguro(a)?',
    text: "¡No podrás revertirlo!",
    confirmButtonText: 'Eliminar',
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showCancellationMessage = () => {
  return Swal.fire({
    icon: 'warning',
    title: '¿Estas seguro(a)?',
    text: "¡No podrás revertirlo!",
    confirmButtonText: 'Anular',
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showReactivationMessage = () => {
  return Swal.fire({
    icon: 'question',
    title: '¿Estas seguro(a)?',
    text: "¡No podrás revertirlo!",
    confirmButtonText: 'Reactivar',
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showCloneMessage = () => {
  return Swal.fire({
    icon: 'question',
    title: '¿Deseas clonar esta ficha?',
    text: "Ingresa el nombre de la nueva ficha, si no lo ingresas, el sistema creará un nombre automáticamente",
    confirmButtonText: 'Clonar',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showSignatureMessage = () => {
  return Swal.fire({
    icon: 'question',
    title: '¿Estas seguro(a)?',
    text: "¡No podrás revertirlo!",
    confirmButtonText: 'Firmar',
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showRejectionMessage = () => {
  return Swal.fire({
    icon: 'question',
    title: '¿Deseas rechazar este documento?',
    text: "Ingresa el motivo de rechazo",
    confirmButtonText: 'Rechazar',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
    preConfirm: (inputValue) => {
      if (!inputValue) {
        Swal.showValidationMessage('El motivo de rechazo es requerido');
      }
    }
  })
}

const showResponseObservacionMessage = (observacion: string) => {
  return Swal.fire({
    icon: 'question',
    title: '¿Deseas escribir alguna respuesta?',
    text: `${observacion} `,
    confirmButtonText: 'Guardar',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
  })
}

const showValidatePassword = () => {
  return Swal.fire({
    icon: 'info',
    title: 'Ingresa tu contraseña',
    text: `Necesitamos Validar tu Información`,
    confirmButtonText: 'Guardar',
    input: 'password',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
  })
}


const showResponseValidacionMessage = (detalle: string,option:any) => {
  return Swal.fire({
    icon: 'info',
    title: 'Autenticación multifactor',
    text: `${detalle} `,
    input: 'number',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    confirmButtonText: 'Verificar',
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },    
    preConfirm: async (codigo) => {
      try {
        option.codigo = codigo
        await validarCodigo(option)
        .then((response) => { 
          if(!response.success){
            return Swal.showValidationMessage(`
              ${response.message}
            `);
          }          
        })                                    
        .catch((error) => {
            return Swal.showValidationMessage(`
              ${error.message}
            `);
        })
        
      } catch (error) {
        Swal.showValidationMessage(`
          Request failed: ${error}
        `);
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  })
}

const showCloseProcessMessage = (textConfirm: string) => {
  return Swal.fire({
    icon: 'question',
    title: '¿Deseas cerrar este proceso?',
    text: 'Ingresa informacion adicional',
    confirmButtonText: textConfirm,
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off',
    },
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary btn-sm',
      cancelButton: 'btn btn-secondary btn-sm',
    },
    preConfirm: (inputValue) => {
      if (!inputValue) {
        Swal.showValidationMessage('La información es requerida')
      }
    },
  })
}


export {
  showInfoCopy,
  showMessage,
  showInfoHtml,
  showInfoHtmlCenter,
  showDeleteMessage,
  showCloneMessage,
  showResponseObservacionMessage,
  showResponseValidacionMessage,
  showValidatePassword,
  showCancellationMessage,
  showReactivationMessage,
  showRejectionMessage,
  showSignatureMessage,
  showCloseProcessMessage
}

