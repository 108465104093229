import React, { useContext, useState, useEffect, FC } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { CollapsibleFieldExternalItem } from './CollapsibleFieldExternalItem'
import { Campos, ProcesoFichas, Seccion } from '../../../../../documentacion/MiFicha/FIchaInterface'
import { ProcessSheet, Sheet } from '../../../../documento/documentInterface'
import { PartnerContext } from '../../../PartnerContexts'

const grid = 3

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#c7fff0' : '#EFEFEF',
  padding: grid,
  width: '100%',
  borderRadius: 10,
})

interface FieldExternal {label: string, fields: any[]}

interface MyComponentProps {
  fieldExternal: FieldExternal[];
  ind: number;
  onUpdate: (updatedData: any) => void;
}

const DraggableFieldExternalList: FC<MyComponentProps> = (props) => {
  const {updateFieldMigration,setUpdateFieldMigration} = useContext(PartnerContext)
  const {fieldExternal, ind, onUpdate } = props
  const [loading, setLoading] = useState(true)
  const [formDataFieldExternal, setFormDataFieldExternal] = useState(fieldExternal)

  useEffect(() => {
    setFormDataFieldExternal(fieldExternal)
    setLoading(false)
  }, [fieldExternal])

  useEffect(() => {
    HandleUpdateFieldMigration()
  }, [formDataFieldExternal])
  
  const HandleUpdateFieldMigration = () => {

  };
  
  const handleUpdateSheet = (ind:number,groupData:any) => {
    const newGroups = [...formDataFieldExternal]
    newGroups[ind] = groupData
    onUpdate(newGroups)
    //AQUI HARE MI LOGICA;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {fieldExternal.length === 0 &&  loading? (
        <div>Loading...</div>
      ) : (
        <Droppable droppableId={`droppable` + ind}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {(formDataFieldExternal || []).map((item: FieldExternal , index) => (
                <CollapsibleFieldExternalItem
                  key={item.label}
                  ind={ind}
                  item={item}
                  index={index}
                  onUpdate={(updatedData) => handleUpdateSheet(index, updatedData)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  )
}

export { DraggableFieldExternalList }
