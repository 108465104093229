import {useContext} from 'react'

import { ShowExportarPlantillaModalFormWrapper } from './ShowExportarPlantillaModalFormWrapper'
import { ShowExportarPlantillaModalHeader } from './ShowExportarPlantillaModalHeader'
import { PartnerContext } from '../../../PartnerContexts'

const ShowExportarPlantillaEditModal = () => {

  const {showExportPlantillaExcel} = useContext(PartnerContext)

  return (
    <>
      {showExportPlantillaExcel && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-md'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <ShowExportarPlantillaModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <ShowExportarPlantillaModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {ShowExportarPlantillaEditModal}
