import {format, parseISO} from 'date-fns'
import {FC, useContext, useEffect, useState} from 'react'
import {DragDropContext, Draggable, DropResult, Droppable} from 'react-beautiful-dnd'
import {downloadFileS3, getFileInPDF} from '../../../../../../services/files'
import {DataUser} from '../../../../../documentacion/MiFicha/DataUserInterface'
import './styles.css'

import {KTIcon, KTSVG} from '../../../../../../../_metronic/helpers'
import {showMessage} from '../../../../../../shared/Alerts'
import OverlayLoading from '../../../../documento/componente/OverlayLoading'
import { Section } from '../../../../fichas/Sheet'
import { Columns } from 'react-bootstrap-icons'
import { Col, Row } from 'react-bootstrap'
import { PartnerContext } from '../../../PartnerContexts'

const grid2 = 3

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: grid2,
  margin: `0 0 ${grid2}px 0`,

  background: isDragging ? '#18c073' : 'white',
  borderRadius: 10,
  ...draggableStyle,
})

const getItems = (count: number) =>
  Array.from({length: count}, (_, k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }))

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const grid = 3

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#c7fff0' : '#EFEFEF',
  padding: grid,
  width: '100%',
  borderRadius: 10,
})

const tiposCampos = [
  'Caja de Texto',
  'Lista Desplegable',
  'checkbox',
  'radiobutton',
  'Numero',
  'Fecha',
]

interface MyComponentProps {
  indProcesoFicha: number
  sectionItem: Section
  onUpdate: (updatedData: any) => void
}

const DraggableFieldList: FC<MyComponentProps> = (props) => {
  const {sectionItem, onUpdate} = props

  const {fieldData, setOpenAssignmentModal,setFieldData,updateFieldAssing,setUpdateFieldAssing} = useContext(PartnerContext)
  const [formDataSeccion, setFormDataSeccion] = useState<Section>(sectionItem)
  const [formDataCampos, setFormDataCampos] = useState(sectionItem.fields)
  const [cargandoFile, setCargandoFile] = useState(false)

  const usuarioJSON = localStorage.getItem('userData')
  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }
  // const idusuario = usuario?.userId
  const nameusuario = usuario?.userName



  useEffect(() => {
    onUpdate(formDataSeccion)
  }, [sectionItem, formDataSeccion])

  useEffect(()=>{
    if(updateFieldAssing){
      if(fieldData?.idseccion === formDataSeccion.id){
        setUpdateFieldAssing(false)
        setFormDataSeccion((formDataSeccion) => {
          if(formDataSeccion.fields){
            const index = formDataSeccion.fields.findIndex(field => String(field.id) === String(fieldData.id));
            const newField = [...formDataSeccion.fields]
            newField[index] = {
              ...newField[index],
              tagIntegracion: fieldData.tagIntegracion,
            }
            setFormDataCampos(newField)
            return {...formDataSeccion, fields: newField}    
          }else{
            return {...formDataSeccion}
          }
        })
      }      
    }
  },[updateFieldAssing])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
  }

  const handleFieldAssing = (field: any) => {
    if(formDataSeccion.id === field.i){

    }
  }

  const handleInputChange = (event: any, index: any) => {
    const {value} = event.target
    let fieldValue = value

    setFormDataSeccion((formDataSeccion) => {
      if(formDataSeccion.fields){
        const newField = [...formDataSeccion.fields]
        newField[index] = {
          ...newField[index],
          tagIntegracion: fieldValue,
        }
        setFormDataCampos(newField)
        return {...formDataSeccion, fields: newField}

      }else{
        return {...formDataSeccion}
      }
    })
  }

  const addAssignment = (item:any) => {
    setFieldData(item)
    setOpenAssignmentModal(true)
  }

  return (
    <>
        <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {(formDataCampos || []).map((item, index) => (
                      <>
                        <Draggable key={item.id} draggableId={`droppable_campo${index}`} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <div className='grid-container-obs' style={{position: 'relative'}}>
                                <div
                                  className='grid-item'
                                  style={{marginLeft: '10px', marginTop: '8px', fontWeight: 'bold'}}
                                >
                                  {item.name}
                                </div>
                                <div
                                  className='grid-item'
                                  style={{marginLeft: '5px', marginTop: '8px', fontWeight: 'normal'}}
                                >
                                  <label> {item.tagIntegracion?item.tagIntegracion:""} </label>
                                </div>
                                <div className='grid-item' style={{marginLeft: '5px'}}>
                                  <Row>
                                    <Col sm="2">
                                      <a
                                        href='#!'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        title='Asignar a'
                                        onClick={() => addAssignment(item)}
                                      >
                                        <KTIcon
                                          iconName='arrow-up-right'
                                          className='fs-3'
                                        />
                                      </a>
                                    </Col>
                                    <Col sm="10">
                                      <input
                                              type='text'
                                              style={{border:item.tagIntegracion===""?'1px solid red': ''}}
                                              className={`form-control form-control-sm form-control-solid`}
                                              name={item.id}
                                              value={item.tagIntegracion}
                                              onChange={(event) => handleInputChange(event, index)}
                                              placeholder='Ingrese Etiqueta'
                                            />
                                    </Col>
                                  </Row>
                                </div>
                                
                              </div>
                            </div>
                          )}
                        </Draggable>       
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          
        </>

      {cargandoFile && <OverlayLoading />}
    </>
  )
}

export {DraggableFieldList}
