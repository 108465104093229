import { Button, Col, Dropdown, Form, Row, Stack } from "react-bootstrap"
import { KTCard, KTCardBody, KTIcon } from "../../../../../../_metronic/helpers"
import Select from 'react-select'
import { useContext, useEffect, useState } from "react";
import { getActualizarGroupField, getExternalById, getExternals, updateExternalData } from "../../../../../services/external";
import ReactJson from "react-json-view";
import { initExternalData, PartnerContext } from "../../PartnerContexts";
import GroupFieldServicio from "./GroupFieldServicio";
import * as excelJs from 'exceljs';
import { useGlobalOverlayLoading } from '../../../../../shared/hooks/useGlobalOverlayLoading';
import { formatDateToDMY } from "../../../../../helpers/dateFunctions";
import { showMessage } from "../../../../../shared/Alerts";

interface MyProps {

}

type Columns = {NameGrupo: string; NameCampo: string,Value: string}[]

const UpdateServicio : React.FC<MyProps> = () => {
    const {reloadServiceExternal,setExternalData,setReloadServiceExternal,setShowCargarPlantillaExcel} = useContext(PartnerContext)
    const [listService, setListService] = useState<any[]>([]);
    const [idSelectService,setIdSelectService] = useState("")
    const [edit,setEdit] = useState(false)
    const [reloadExternalApi,setReloadExternalApi] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataServiceExternal,setDataServiceExternal] = useState(initExternalData)
    const {setGlobalOverlayLoading} = useGlobalOverlayLoading()

    useEffect(()=>{
        refetchData()
    },[])
    
    useEffect(()=>{
        if(reloadServiceExternal){
            setReloadServiceExternal(false)
            refetchData()
        }
    },[reloadServiceExternal])

    useEffect(()=>{
        if(idSelectService) getExternalId(idSelectService)
    },[idSelectService])

    useEffect(() => {
        if(reloadExternalApi){
            setReloadExternalApi(false)
            reloadDataExternalApi()
        }
    },[reloadExternalApi])

    const reloadDataExternalApi = async () => {
        setLoading(true)
        await getActualizarGroupField(idSelectService)
        .then((response)=>{
            setLoading(false)
            if (response.success) {
                showMessage("success","Actualizado",response.message)
                setDataServiceExternal(initExternalData)
                setReloadServiceExternal(true)
            } else {
                showMessage("error","Hubo un problema",response.message)
                setDataServiceExternal(initExternalData)
                setReloadServiceExternal(true)
                console.log(response.msg)
            }
        })
    }

    const refetchData = async () => {
        await getExternals({typedata:"NAME_SERVICES"})
        .then((response)=>{
            if (response.success) {
                setListService(response.data.map((x: any) => ({ label: x.service, value: x._id })))
                if(idSelectService) getExternalId(idSelectService)
            } else {
                console.log(response.msg)
            }
        })
    }

    
    const handleChangeService = (e: any) => {
        setIdSelectService(e.value)
    }

    const getExternalId = async (id:string) =>{
        await getExternalById(id)
        .then((response) => {
            setDataServiceExternal(response.data)
            setExternalData(response.data)
            setEdit(false)           
        })
        .catch((error) => console.log(error))
    }

    const handleChange = (e: any) => {
        setDataServiceExternal({...dataServiceExternal, [e.target.name]: e.target.value})
    }

    const handleExport = () => {
        const columns : Columns = []
        columns.push({NameGrupo:"Servicio - Grupo 1 ",NameCampo:"label",Value:"Razón Social"})
        columns.push({NameGrupo:"Servicio - Grupo 1 ",NameCampo:"identifier",Value:"CL_RazonSocial"})
        columns.push({NameGrupo:"Servicio - Grupo 1 ",NameCampo:"type",Value:"string"})
        exportExcelJS(columns)
    }

    const exportExcelJS = async (columns: Columns) => {
        const workbook = new excelJs.Workbook()
        const worksheet = workbook.addWorksheet()

        worksheet.mergeCells(`A1:${getExcelLetter(columns.length)}1`);
        const groupCell = worksheet.getCell('A1');
        groupCell.value = columns[0].NameGrupo;
        groupCell.font = {
            name: 'Calibri',
            size: 14,
            bold: true,
            color: { argb: 'FFFFFF' },
        };
        groupCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0A58CA' },
            bgColor: { argb: '' },
        };
        groupCell.alignment = { vertical: 'middle', horizontal: 'center' };
        /* let Ini = 1
        let fin = 0
        Object.keys(objectSeccion).map((key,ind) => {
            fin = fin + objectSeccion[key]
            worksheet.mergeCells(`${getExcelLetter(Ini)}2:${getExcelLetter(fin)}2`)
            
            const groupCell = worksheet.getCell(`${getExcelLetter(Ini)}2`);
            groupCell.value = key;
            groupCell.font = {
            name: 'Calibri',
            size: 13,
            bold: true,
            color: { argb: 'FFFFFF' },
            };
            groupCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: getRandomColorRGB() },
            bgColor: { argb: '' },
            };
            
            groupCell.alignment = { vertical: 'middle', horizontal: 'center' };  
            
            Ini = Ini + objectSeccion[key]      
        }) */

        // Adding Header Row
        const header = columns.map((el) => el.NameCampo);
        const headerKeys = columns.map((el) => ({ key: el.NameCampo }));
        const value = columns.map((el) => el.Value);
        const headerRow = worksheet.addRow(header);
        const contentRow = worksheet.addRow(value);
        worksheet.columns = headerKeys;
        headerRow.eachCell((cell) => {
        cell.alignment = {
            horizontal: 'center',
        };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DCE0F7' },
            bgColor: { argb: '' },
        };
        cell.font = {
            color: { argb: '45494E' },
            size: 12,
        };
        });
        
        contentRow.eachCell((cell) => {
        cell.alignment = {
            horizontal: 'center',
        };
        cell.font = {
            color: { argb: '45494E' },
            size: 12,
        };
        if(cell.value === "date"){
            cell.value = formatDateToDMY(new Date());
            cell.numFmt = 'dd/mm/yyyy';
        }
        });
        
        
        if (worksheet.columns) {
        worksheet.columns.forEach(function (column) {
            if (column) {
            let dataMax = 0;
            column.eachCell && column.eachCell({ includeEmpty: true }, function (cell, idx) {
                const columnLength = cell.value ? String(cell.value).length : 0;
                if (columnLength > dataMax) {
                dataMax = columnLength;
                }
            });
            if (column) {
                column.width = dataMax < 15 ? 15 : dataMax;
            }
            }
        });
        }

        worksheet.addRows([{}, {}, {}]);
        
        const rowToLock = worksheet.getRow(5);
        rowToLock.eachCell((cell) => {
        cell.protection = {
            locked: true,
        };
        });




        workbook.xlsx.writeBuffer()
        .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'mi_archivo_excel.xlsx';
        a.click();
        })
        .finally(() => setGlobalOverlayLoading(false))
    
    }

    const getExcelLetter = (number:number) => {
        let result = '';
        while (number > 0) {
            const remainder = (number - 1) % 26;
            result = String.fromCharCode(65 + remainder) + result;
            number = Math.floor((number - 1) / 26);
        }
        return result;
    };
    
    const handleUpdateExternal = (updatedData:any) => {
        setDataServiceExternal({...dataServiceExternal,fields:updatedData})
    };
    
    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (dataServiceExternal._id !== "") {
            setLoading(true)
            updateExternalData(dataServiceExternal._id,dataServiceExternal)
            .then((response) => {
                if (response.success) {  
                showMessage("success","Actualizado",response.message)
                setDataServiceExternal(initExternalData)
                setReloadServiceExternal(true)
                setLoading(false)
                } else {
                setLoading(false)
                console.log(response)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
        }
    }

    return (
        <>
            <div className="card card-custom ">
                <div className="card-header d-flex align-items-center"  >
                    <Stack direction='vertical' gap={1}>
                        <span className='fw-bold'>Seleccione Servicio:</span>
                        <Select
                            styles={{
                                control: (provided) => ({
                                ...provided,
                                fontSize: '13px',
                                minHeight: '42px', // Reducción del alto
                                height: '42px',
                                }),
                            }}
                            placeholder='Seleccione el servicio'
                            options={listService}
                            onChange={handleChangeService}
                        />
                    </Stack>                    
                    <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-light-info btn-sm mx-3'
                        title='Editar'
                        onClick={() => setEdit(true)}
                        >
                        <KTIcon iconName='pencil' className='fs-3' />
                    </a>
                    {/* <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-light-danger btn-sm mx-3'
                        title='Eliminar'
                        // onClick={() => handleActualizar(item)}
                        >
                        <KTIcon iconName='cross' className='fs-3' />
                    </a> */}
                </div>
                <div className="card-body card-scroll h-620px">
                    { !edit ? (
                        <Row className='mb-3'>
                            <label className="my-2"><b>Servicio : </b>{dataServiceExternal.service}</label>
                            {dataServiceExternal.api && (<label className="my-2"><b>Api Vinculado: </b>{dataServiceExternal.api} <a
                                href='#'
                                className={`btn ${!loading?'btn-icon':''} btn-bg-light btn-light-info btn-sm mx-3`}
                                title='Actualizar Los Campos'
                                onClick={() => setReloadExternalApi(true)}
                                >
                                {!loading && (<KTIcon iconName='loading' className='fs-3' />)}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        Actualizando...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                )}
                                
                            </a></label>) }
                            
                            <ReactJson src={dataServiceExternal.fields} />
                        </Row>
                    ):(
                        <Row className='mb-3'>
                            <Form onSubmit={handleSubmit}>
                                <div className="card card-custom">
                                    <div className="card-body card-scroll h-450px">                    
                                        <Form.Group controlId='formService'>
                                            <Form.Label>Ingrese Nombre Perfil</Form.Label>
                                            <Form.Control
                                            type='text'
                                            className='form-control-sm'                      
                                            required
                                            value={dataServiceExternal.service}
                                            placeholder='Ingrese un codigo'
                                            onChange={handleChange}  
                                            name='service'
                                            />
                                        </Form.Group>   
                                        <Form.Group controlId='formNombre' className="mt-3">
                                            <Form.Label>Ingrese Api (Opcional)</Form.Label>
                                            <Form.Control
                                            type='text'
                                            className='form-control-sm'                      
                                            required
                                            value={dataServiceExternal.api}
                                            placeholder='Ingrese Api vinculada'
                                            onChange={handleChange}  
                                            name='api'
                                            />
                                        </Form.Group>                
                                        <Form.Group controlId='formFieldExcel' className="mt-3">   
                                            <Stack direction='horizontal' gap={2}>
                                                <Dropdown>
                                                    
                                                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                    <KTIcon iconName='file-down' className='fs-2' />
                                                    Carga Masiva Nuevo Grupo de Campos
                                                </Dropdown.Toggle>
                                                
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href='#' onClick={handleExport} >
                                                    Exportar Plantilla
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href='#' onClick={() => {setShowCargarPlantillaExcel(true)} }>
                                                    Cargar Plantilla
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                                </Dropdown>
                                            </Stack>                                      
                                        </Form.Group>               
                                        <Form.Group controlId='formField' className="mt-3">
                                            <GroupFieldServicio key={"idGroupFieldServicio"} onUpdate={(updatedData) => handleUpdateExternal(updatedData)} field={dataServiceExternal.fields} />
                                        </Form.Group>
                                    </div>
                                    <div className="card-footer">
                                        <div className='d-flex justify-content-end'>
                                            <Button variant='danger' type='button' onClick={()=>{setEdit(false)}}>
                                                {'Cancelar'}
                                            </Button>
                                            <Button variant='success' className="mx-2" type='submit'>
                                                {!loading && edit && 'Actualizar'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                    Subiendo...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Row>
                    )}    
                </div>
                <div className="card-footer">
                    
                </div>
            </div>
        </>
    )
}
export default UpdateServicio