import { Fragment, useContext, useEffect, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import { formatDateToDMY } from '../../../../helpers/dateFunctions'
import { downloadMultipleDocumentsByUser } from '../../../../services/documentSent'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { showMessage } from '../../../../shared/Alerts'
import Pagination from '../../../../shared/Pagination'
import SortableTableHeader from '../../../../shared/SortableTableHeader'
import { useGlobalOverlayLoading } from '../../../../shared/hooks/useGlobalOverlayLoading'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { UsersReportContext } from '../UsersReportContext'

const UsersReportTable = () => {
  const [usersList, setUsersList] = useState<any>([])
  const {
    data,
    setCurrentPage,
    currentPage,
    totalPages,
    isLoading,
    selectedUsers,
    setSelectedUsers,
    processId,
    sortBy,
    setSortBy,
  } = useContext(UsersReportContext)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()

  const navigate = useNavigate()
  const location = useLocation()
    const { selectedRole } = useAuth2()
    const [permissions, setPermissions] = useState<string[]>([])
    
    useEffect(() => {
      const fetchPermissions = async () => {
        const response = await fetchMenuActionsByRole(selectedRole)
        if (response.success) {
          const currentPath = location.pathname
          const subMenuResponse = await fetchSubMenuByUrl(currentPath)
          if (subMenuResponse.success) {
            const subMenuId = subMenuResponse.data.id_hijo
            const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
            setPermissions(permissionsForSubMenu)
          }
        }
      }
  
      fetchPermissions()
    }, [selectedRole])
  useEffect(() => {
    console.log(data)
    setUsersList(data)
  }, [data])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handleMainToggle = () => {
    if (selectedUsers.length === data.length) {
      setSelectedUsers([])
    } else {
      setSelectedUsers(data.map((item: any) => item.idUsuario))
    }
  }

  const handleToggle = (id: string) => {
    const updatedCheckboxes = [...selectedUsers]
    const index = updatedCheckboxes.indexOf(id)

    if (index === -1) {
      updatedCheckboxes.push(id)
    } else {
      updatedCheckboxes.splice(index, 1)
    }

    setSelectedUsers(updatedCheckboxes)
  }

  const downloadDocumentsByUser = (userId: string) => {
    setGlobalOverlayLoading(true)
    downloadMultipleDocumentsByUser({userId})
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = 'multiple_documents'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        console.log(error)
        showMessage('error', 'Hubo un error', error.message)
      })
      .finally(() => setGlobalOverlayLoading(false))
  }

  const handleRedirection = (id: string) => {
    console.log(id)
    navigate(`/seguimiento/historialdocumentos/detalle/${id}`)
  }

  const handleSort = (columnName: string, sortDirection: string) => {
    setSortBy({columnName, sortDirection})
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 table-hover'>
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
              <th className='w-25px text-start'>
                {usersList.length > 0 && (
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={'checkbox-documents-report-all'}
                      checked={selectedUsers.length === data.length}
                      onChange={handleMainToggle}
                    />
                  </div>
                )}
              </th>
              <th className='text-start'>Unidad</th>
              <th className='text-start'>Dni</th>
              <SortableTableHeader
                label='Apellidos y Nombres'
                columnName='apellidos'
                handleSort={handleSort}
                activeColumn={sortBy.columnName}
                style={{textAlign: 'left'}}
                labelStyle={{color: '#ffffff'}}
              />
              <SortableTableHeader
                label='Fecha de Ingreso'
                columnName='fechaIngreso'
                handleSort={handleSort}
                activeColumn={sortBy.columnName}
                labelStyle={{color: '#ffffff'}}
              />
              <th className='text-start'>Acciones</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {usersList?.length > 0 ? (
              usersList?.map((item: any, index: number) => (
                <Fragment key={item._id}>
                  <tr>
                    <td className='w-25px p-1'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`checkbox-documents-report-${item.idUsuario}`}
                          checked={selectedUsers.includes(item.idUsuario)}
                          onChange={(e) => handleToggle(item.idUsuario)}
                        />
                      </div>
                    </td>
                    <td className='text-start p-1'>{item.unidad ?? 'Sin unidad'}</td>
                    <td className='text-start p-1'>{item.dni}</td>
                    <td className='min-w-100px p-1'>
                      {item.apellidos} {item.nombres}
                    </td>
                    <td className='text-start min-w-100px p-1'>
                      {formatDateToDMY(item.fechaIngreso)}
                    </td>
                    <td className='text-end p-1'>
                      {permissions.includes('Ver historial documentos') && (
                        <button onClick={() => handleRedirection(item.idUsuario)}>
                          <Badge bg='success-active' className='me-2'>
                            {item.totalPending + item.totalSigned + item.totalRejected}
                          </Badge>
                        </button>
                      )}
                      {permissions.includes('Descargar documentos') && (
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={() => downloadDocumentsByUser(item.idUsuario)}
                          title='Descargar documentos del usuario'
                        >
                          <KTIcon iconName='file-down' className='fs-3' />
                        </button>
                      )}
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        links={generatePaginationLinks(currentPage, totalPages)}
        currentPage={currentPage}
        isLoading={isLoading}
        onPageChange={handlePageChange}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export { UsersReportTable }

