import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react'
import {Dropdown, OverlayTrigger, Stack, Tooltip} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTable} from 'react-table'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatDateToDMY} from '../../../../helpers/dateFunctions'
import {deletePartner, updatePartner} from '../../../../services/colaboradores'
import {deleteDocument, downloadAsZip, getDocuments} from '../../../../services/documentos'
import {busquedaficha, deleteCompleteSheet, ficha, generatePDF} from '../../../../services/fichas'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {changeStatusFicha} from '../../../../services/registroFicha'
import {
  showCancellationMessage,
  showDeleteMessage,
  showMessage,
  showReactivationMessage,
} from '../../../../shared/Alerts'
import {ModernPagination} from '../../../../shared/ModernPagination'
import SortableTableHeader from '../../../../shared/SortableTableHeader'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useQueryResponseData} from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import {usersColumns} from '../../../apps/user-management/users-list/table/columns/_columns'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {ListaSecciones} from '../../usuario/pages/secciones/ListaSecciones'
import {PartnerContext} from '../PartnerContexts'
import { CustomTooltip } from '../../../generales_component/CustomTooltip'
const DocumentsTable = () => {
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const [sortBy, setSortBy] = useState({columnName: '', sortDirection: ''})
  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [fetchStatus, setFechStatus] = useState('')
  const [fetchMessage, setFetchMessage] = useState('')

  const [lista, setLista] = useState([])
  const [selecto, setSelecto] = useState(0)
  const [show, setShow] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [mnuevo, setMnuevo] = useState(false)
  const [nuevo, setNuevo] = useState('')

  // const {searchTerm} = useContext(SheetContext)
  const {
    activeOnly,
    idProceso,
    state,
    stateSigned,
    searchTerm,
    entryDateFrom,
    entryDateTo,
    openObservacionModal,
    unidad,
  } = useContext(PartnerContext)

  const navigate = useNavigate()
  
  const iconsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Enviado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    },
    {
      iconName: 'security-user',
      title: 'Pendiente',
      stateName: 'pendiente',
      color: '#3c50e7',
    },
    {
      iconName: 'security-user',
      title: 'Pendiente',
      stateName: 'pendientesignend',
      color: '#f39c12',
    },    
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    },
  ]
  const location = useLocation()
  const {selectedRole} = useAuth2()
  const userAllowedToAprove = selectedRole?.toLowerCase() === 'admin'
  const [permissions, setPermissions] = useState<string[]>([])
  const [stateSignedAux, setStateSignedAux] = useState(stateSigned)

  useEffect(()=> {
    setStateSignedAux(stateSigned)
    // setLista(lista)
  },[stateSigned])
  

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        console.log('currentpath' + JSON.stringify(currentPath))
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          console.log('PERMISOS' + JSON.stringify(subMenuResponse))
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])
  
  // useEffect(() => {
  //   setLoadingStatus(true)
  //   refetchData({
  //     activeOnly: activeOnly,
  //     sheetStates: state,
  //     id_proceso: idProceso,
  //     searchQuery: searchTerm,
  //     entryDateFrom: entryDateFrom,
  //     entryDateTo: entryDateTo,
  //   })
  // }, [])

  // useEffect(() => {
  //   setLoadingStatus(true)
  //   refetchData({
  //     activeOnly: activeOnly,
  //     sheetStates: state,
  //     searchQuery: searchTerm,
  //   })
  // }, [searchTerm])

  useEffect(() => {
    setLoadingStatus(true)
    refetchData({
      activeOnly: activeOnly,
      type_document: "CPD",
      sheetStates: state,
      id_proceso: idProceso,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
      sortBy: sortBy.columnName,
      sortOrder: sortBy.sortDirection,
      unidad: unidad,
      page: currentPage,
    })
  }, [
    sortBy,
    activeOnly,
    state,
    idProceso,
    entryDateFrom,
    entryDateTo,
    searchTerm,
    unidad,
    currentPage,
  ])

  const refetchData = (options: any) => {
    setLoadingStatus(true)

    getDocuments(options)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)
          setLista(response?.data)
          setTotalPages(response?.totalPages)
          setCurrentPage(response?.page)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleObservar = (id: any, namecolaborador: any) => {
    navigate(`/seguimiento/colaborador/observacion/${id}/${namecolaborador}`)
  }

  const handleValidation = (id: any, namecolaborador: any) => {
    navigate(`/configuracion/validar/actions/${id}/${namecolaborador}`)
  }

  const handleAprove = (id: any) => {}

  /* const valContextProvider = {
    activeOnly,
    state,
    setActiveOnly,
    setState,
    isObservacionModalOpen,
    openObservacionModal,
    closObservacionModal
  } */

  const changePartnerStatus = (id: string, activeState: boolean) => {
    if (activeState === false) {
      showDeleteMessage().then((result) => {
        if (result.isConfirmed) {
          deletePartner(id)
            .then((response) => {
              if (response.success) {
                showMessage('success', '¡Buen trabajo!', response.message)
              } else {
                showMessage('error', 'Hubo un error', response.error)
              }
              refetchData({
                activeOnly: activeOnly,
                type_document: "CPD",
                id_proceso: idProceso,
                sheetStates: state,
                searchQuery: searchTerm,
                entryDateFrom: entryDateFrom,
                entryDateTo: entryDateTo,
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    } else {
      updatePartner(id, {estado: true})
        .then((response) => {
          refetchData({
            activeOnly: activeOnly,
            type_document: "CPD",
            id_proceso: idProceso,
            sheetStates: state,
            searchQuery: searchTerm,
            entryDateFrom: entryDateFrom,
            entryDateTo: entryDateTo,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // refetchData({
    //   activeOnly: activeOnly,
    //   page
    // })
  }

  const handleSelecto = (opt: any) => {
    navigate(`/crafted/pages/configuracion/usuario/pages/fichas/plantilla/Ficha/:${opt}`)
  }

  const handleEliminar = (id: any) => {
    setMeliminar(!meliminar)
    setEliminar(id)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteCompleteSheet(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData({
              activeOnly: activeOnly,
            })
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleActualizar = (id: any) => {
    ficha(id)
      .then((response) => {
        if (response.success) {
          setActualizar(response?.data)
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    setTimeout(() => {
      setMactualizar(!mactualizar)
    }, 1000)
  }

  const handleNuevaSeccion = (idnuevo: any) => {
    setMnuevo(!mnuevo)
    setNuevo(idnuevo)
  }

  const handleSearch = (e: any) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      e.preventDefault()
      e.target.blur()
    }
    const valorbuscado = e.target.value
    if (valorbuscado?.length > 2) {
      if (valorbuscado) {
        busquedaficha(valorbuscado).then((response) => {
          if (response.success) {
            setLista(response?.data)
          } else {
            refetchData({
              activeOnly: activeOnly,
            })
          }
        })
      } else {
        refetchData({
          activeOnly: activeOnly,
        })
      }
    } else {
      refetchData({
        activeOnly: activeOnly,
      })
    }
  }

  const handleCancellation = (id: string) => {
    showCancellationMessage().then((result) => {
      if (result.isConfirmed) {
        deleteDocument(id)
          .then((response) => {
            if (response.success) {
              refetchData({
                activeOnly: activeOnly,
                type_document: "CPD",
                id_proceso: idProceso,
                sheetStates: state,
                searchQuery: searchTerm,
                entryDateFrom: entryDateFrom,
                entryDateTo: entryDateTo,
              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })
  }

  const handleReactivation = (id: string) => {
    showReactivationMessage().then((result) => {
      if (result.isConfirmed) {
        changeStatusRegistroFicha(id, 'completadoPorColaborador')
      }
    })
  }

  const changeStatusRegistroFicha = (id: string, estado: string) => {
    changeStatusFicha(id, {estado})
      .then((response) => {
        if (response.success) {
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          showMessage('error', 'Hubo un error', response.error)
        }
        refetchData({
          activeOnly: activeOnly,
          type_document: "CPD",
          id_proceso: idProceso,
          sheetStates: state,
          searchQuery: searchTerm,
          entryDateFrom: entryDateFrom,
          entryDateTo: entryDateTo,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleViewForm = (opt: any) => {
    navigate(`/crafted/pages/configuracion/usuario/pages/fichas/plantilla/Formulario/:${opt}`)
  }

  const handleUpdate = (id: any) => {
    navigate(`/configuracion/enviodocumentos/${id}`)
  }

  const handleView = (id: any) => {
    navigate(`/seguimiento/cpd-documentos/detalle/${id}`)
  }

  const handleExport = (id: any) => {
    generatePDF()
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
  }

  const handleSwitchChange = (e: any) => {
    console.log(e)
    changePartnerStatus(e.target.id, e.target.checked)
  }

  const handleDownloadAsZip = (id: string) => {
    downloadAsZip(id)
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = 'carga'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        console.log(error)
        showMessage('error', 'Hubo un error', error.message)
      })
  }

  const validateFila =  (signed:number,total:number,statesigned:any,estadocarga:any) : boolean => {
    if(statesigned === ""){
      return true
    }else if(statesigned === "pendiente"){
      if (estadocarga !== "completado" && (signed < total || total == 0) ) return true
    }else if(statesigned === "completado" && total > 0){
      if (signed == total) return true
    }
    return false
  }

  // Custom Toggle Component
  const CustomToggle = React.forwardRef(({onClick}: any, ref: any) => (
    <a
      ref={ref}
      href='#'
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      title='Visualizar ficha'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <KTIcon iconName='dots-square-vertical' className='fs-3' />
    </a>
  ))

  const handleSort = (columnName: string, sortDirection: string) => {
    console.log('Sorting column:', columnName, 'Direction:', sortDirection)
    setSortBy({columnName, sortDirection})
    console.log('SortBy state updated:', sortBy)
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 table-hover'>
            <thead>
              <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
                <th className='text-center'>Unidad</th>
                <th className='text-center'>Codigo</th>
                <SortableTableHeader
                  label='Nombre de Carga'
                  columnName='nombre'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Proceso'
                  columnName='idProceso'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Fecha'
                  columnName='createdAt'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Estado Carga'
                  columnName='estadoCarga'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <th className='text-center'>Estado Firma</th>
                <th className='text-center'>Resumen</th>
                <th className='text-end'>Opciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista?.map((item: any, index: any) => {
                  const hasSomeDenied = item?.idFiles?.some((file: any) => {
                    if (file.detalle_niveles == null || file.detalle_niveles?.length === 0) {
                      return false
                    } else {
                      return file.detalle_niveles?.some(
                        (level: any) => level.detalle?.estado === 'rechazado'
                      )
                    }
                  })

                  const vali: boolean = validateFila(
                    item.summary.signed,
                    item.summary.total,
                    stateSigned,
                    item.estadoCarga
                  )

                  return (
                    vali && (
                      <Fragment key={item._id}>
                        <tr>
                          <td className='text-center min-w-100px p-1'>
                            {item.unidad && item.unidad.length > 0
                              ? item.unidad.map((u: any) => u.DesUnid).join(', ')
                              : 'Sin Unidad'}
                          </td>
                          <td className='text-center min-w-100px p-1'>{item.codigo ?? 'Sin Codigo'}</td>
                          <td
                            className='p-1'
                            style={{width: '10%', wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}
                          >
                            {item.nombre}
                          </td>
                          <td className='text-center min-w-100px p-1'>{item.idProceso.nombre}</td>
                          <td className='text-center min-w-100px p-1'>
                            {formatDateToDMY(item.createdAt)}
                          </td>
                          <td className='text-center min-w-100px p-1'>
                            <span
                              style={{
                                backgroundColor: `${
                                  iconsData.find((e) => e.stateName === item.estadoCarga)?.color
                                }`,
                                color: 'white',
                                padding: '0.2em 0.5em',
                                borderRadius: '0.25rem',
                                marginRight: '0.5em',
                                fontSize: '0.8em',
                              }}
                            >
                              {iconsData.find((e) => e.stateName === item.estadoCarga)?.title}
                            </span>
                          </td>
                          <td>
                            <span
                              className='p-1'
                              style={{
                                backgroundColor: `${
                                  iconsData.find(
                                    (e) =>
                                      e.stateName ===
                                      (item.summary.signed === item.summary.total &&
                                      (item.estadoCarga === 'completado' || item.summary.total > 0)
                                        ? 'completado'
                                        : 'pendientesignend')
                                  )?.color
                                }`,
                                color: 'white',
                                borderRadius: '0.25rem',
                                marginRight: '0.5em',
                                fontSize: '0.8em',
                              }}
                            >
                              {item.summary.signed === item.summary.total &&
                              (item.estadoCarga === 'completado' || item.summary.total > 0)
                                ? 'Completado'
                                : 'Pendiente'}
                            </span>
                          </td>

                          <td className='text-center min-w-100px p-1'>
                            <OverlayTrigger
                              placement='left'
                              overlay={
                                <Tooltip id='pending-tooltip'>
                                  Documentos pendientes de firma
                                </Tooltip>
                              }
                            >
                              <span
                                style={{
                                  backgroundColor: '#f39c12',
                                  color: 'white',
                                  padding: '0.2em 0.5em',
                                  borderRadius: '0.25rem',
                                  marginRight: '0.5em',
                                  fontSize: '0.8em',
                                  width: '4em',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                }}
                              >
                                {item.summary.pending}
                              </span>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip id='pending-tooltip'>Documentos firmados</Tooltip>}
                            >
                              <span
                                style={{
                                  backgroundColor: '#2ecc71',
                                  color: 'white',
                                  padding: '0.2em 0.5em',
                                  borderRadius: '0.25rem',
                                  marginRight: '0.5em',
                                  fontSize: '0.8em',
                                  width: '4em',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                }}
                              >
                                {item.summary.signed}
                              </span>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement='right'
                              overlay={
                                <Tooltip id='pending-tooltip'>Documentos rechazados</Tooltip>
                              }
                            >
                              <span
                                style={{
                                  backgroundColor: '#e74c3c',
                                  color: 'white',
                                  padding: '0.2em 0.5em',
                                  borderRadius: '0.25rem',
                                  marginRight: '0.5em',
                                  fontSize: '0.8em',
                                  width: '4em',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                }}
                              >
                                {item.summary.rejected}
                              </span>
                            </OverlayTrigger>
                          </td>

                          <td className='text-end d-flex align-self-end justify-content-end p-1'>
                            {item.deniedDocs > 0 && (
                              <CustomTooltip
                                className='btn btn-icon btn-danger btn-sm'
                                icon='information-4'
                                placement='top'
                                children={`Hay ${item.deniedDocs} documentos rechazados dentro de los niveles de aprobación`}
                              />
                            )}
                            <Stack direction='horizontal' gap={1}>
                              {permissions.includes('Ver Detalle') && (
                                <a
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  title='Ver detalle'
                                  onClick={() => handleView(item._id)}
                                >
                                  <KTIcon iconName='eye' className='fs-3' />
                                </a>
                              )}
                              {permissions.includes('Opciones Documento') && (
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />

                                  <Dropdown.Menu className='fs-7'>
                                    {/* <Dropdown.Item onClick={() => handleCancellation(item._id)}>
                                    Cancelar Carga
                                  </Dropdown.Item> */}
                                    <Dropdown.Item onClick={() => handleDownloadAsZip(item._id)}>
                                      Descargar Documentos
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </Stack>
                          </td>
                        </tr>
                        {item._id === selecto ? (
                          <ListaSecciones
                            id={selecto}
                            mnuevo={mnuevo}
                            setMnuevo={setMnuevo}
                            nuevo={nuevo}
                          />
                        ) : null}
                      </Fragment>
                    )
                  )
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <UsersListPagination /> */}
        {/* <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        /> */}

        <ModernPagination
          totalPages={totalPages}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
          siblings={5}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export {DocumentsTable}
