import { Fragment, useContext, useEffect, useState } from "react"
import { Button, Col, Dropdown, Form, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap"
import { KTCard, KTCardBody, KTIcon } from "../../../../../_metronic/helpers"

import { getAllFindTabla } from "../../../../services/tablas"
import { asignarEmpresas, asignarUnidadByEmpresa, desasignarEmpresas, desasignarUnidadByEmpresa, getAllCompaniesByPais, getUnidadesByEmpresaAsignadas } from "../../../../services/companies"
import { showMessage } from "../../../../shared/Alerts"
/* import { AsignacionTablaTable1 } from "./Unidad/AsignacionTablaTable1"
import { AsignacionTablaTable2 } from "./Unidad/AsignacionTablaTable2" */
import Select from 'react-select'
import { getExternals } from "../../../../services/external"
import CreateServicio from "./Custom2/CreateServicio"
import UpdateServicio from "./Custom2/UpdateServicio"
import { ShowCargaExcelModal } from "./Custom2/show-carga_excel-modal/ShowCargaExcelModal"
import { ShowExportarPlantillaEditModal } from "./Custom2/show-exportar-plantilla-modal/ShowExportarPlantillaModal"


const WNominaTrayCustom2 = () => {
    const [listService, setListService] = useState<any[]>([]);
    const [idSelectService,setIdSelectService] = useState("")

    useEffect(()=>{
        refetchData()
    },[])
    
    const refetchData = async () => {
        await getExternals({typedata:"NAME_SERVICES"})
        .then((response)=>{
            if (response.success) {
                setListService(response.data.map((x: any) => ({ label: x.service, value: x._id })))
            } else {
                console.log(response.msg)
            }
        })
    }


    return (
        <>
            <KTCard>
                <KTCardBody>
                    <Row className='mt-3'>
                        <Col xs={5} lg={5} xl={5} sm>
                            <CreateServicio key={"idCreateServcio"}  />
                        </Col>
                        <Col xs={7} lg={7} xl={7}  sm>                                  
                            <UpdateServicio key={"idUpdateSercio"}  />
                        </Col>
                    </Row>

                </KTCardBody>            
            </KTCard>
            <ShowCargaExcelModal />
            <ShowExportarPlantillaEditModal />
        </>
        
    )
}

export { WNominaTrayCustom2 }