import { useContext, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { External, initExternalData, PartnerContext } from "../../PartnerContexts"
import { crearExternalData } from "../../../../../services/external"
import { showMessage } from "../../../../../shared/Alerts"



interface MYProps {

}

const CreateServicio : React.FC<MYProps> = ()=>{
    const {setReloadServiceExternal}=useContext(PartnerContext)
    const [dataServicio,setDataServicio] = useState<External>(initExternalData)
    const [loading, setLoading] = useState(false)
    const [editButton, setEditButton] = useState(false);


    const handleChange = (e: any) => {
        /* if(editButton){
            if(e.target.name === "nombre_perfil") setPerfil({...perfil, [e.target.name]: e.target.value})
        }else { */
            setDataServicio({...dataServicio, [e.target.name]: e.target.value})
        //}
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (dataServicio._id === "") {
            setLoading(true)
            crearExternalData(dataServicio)
            .then((response) => {
                if (response.success) {  
                showMessage("success","Agregado",response.message)
                setDataServicio(initExternalData)
                setReloadServiceExternal(true)
                setLoading(false)
                } else {
                setLoading(false)
                console.log(response)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
        }else{
            /* setLoading(true)
            updateRolData(perfil._id,perfil)
            .then((response) => {
                if (response.success) {
                setReloadTable(true)
                showMessage("success","Actualizado",response.message)
                setPerfilData(DataPerfil)
                setEdit(false)
                setLoading(false)
                } else {
                setLoading(false)
                console.log(response)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            }) */
        }
    }


    const clear = ()=>{
        // setEdit(false)
        setDataServicio(initExternalData)
    }

    return (
        <>
        
            <Form onSubmit={handleSubmit}>
                <div className="card card-custom">
                    <div className="card-header">
                        <h3 className="card-title">Crear Servicio</h3>
                    </div>
                    <div className="card-body card-scroll h-200px">                    
                        <Form.Group controlId='formNombre'>
                            <Form.Label>Ingrese Nombre Servicio</Form.Label>
                            <Form.Control
                            type='text'
                            className='form-control-sm'                      
                            required
                            value={dataServicio.service}
                            placeholder='Ingrese Nombre'
                            onChange={handleChange}  
                            name='service'
                            />
                        </Form.Group>
                        <Form.Group controlId='formNombre' className="mt-3">
                            <Form.Label>Ingrese Api (Opcional)</Form.Label>
                            <Form.Control
                            type='text'
                            className='form-control-sm'                      
                            required
                            value={dataServicio.api}
                            placeholder='Ingrese Api vinculada'
                            onChange={handleChange}  
                            name='api'
                            />
                        </Form.Group>
                    </div>
                    <div className="card-footer">
                        <div className='d-flex justify-content-end'>
                            {!loading && editButton &&                       
                            <Button className='mx-2' variant='light' 
                            type='reset' 
                            onClick={() => {
                                clear()
                            }}>
                                Cancelar
                            </Button>
                            }
                            <Button variant='success' type='submit'>
                            {!loading && !editButton && 'Registrar'}
                            {!loading && editButton && 'Actualizar'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                Subiendo...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default CreateServicio