

export const getExcelLetter =(number:number) => {
  let result = '';
  while (number > 0) {
      const remainder = (number - 1) % 26;
      result = String.fromCharCode(65 + remainder) + result;
      number = Math.floor((number - 1) / 26);
  }
  return result;
}


