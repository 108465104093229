import React, {useContext, useEffect, useState} from 'react'
import {ListGroup, Badge, Row, Col, Form, Button} from 'react-bootstrap'
// import { ListTags } from '../documentInterface';
import { showInfoCopy, showMessage } from '../../../../../shared/Alerts';
import { Field, Section, Sheet } from '../../../fichas/Sheet'
import { ListFieldsExport, ProcessSheet } from '../../../documento/documentInterface';
import * as excelJs from 'exceljs';
import { PartnerContext } from '../../PartnerContexts';
import { number, string } from 'yup';
import { actualizarCampoCheckExcel, actualizarCampoTagIntegracion } from '../../../../../services/campos';
import { group } from 'console';
import { formatDateToDMY } from '../../../../../helpers/dateFunctions';
import { useGlobalOverlayLoading } from '../../../../../shared/hooks/useGlobalOverlayLoading';
import { getUnidadesEmpresas } from '../../../../../services/companies';
import { useAuth2 } from '../../../../authv2/Auth2Provider';
import { PdfLoadingSpinner } from '../../../../generales_component/PdfLoadingSpinner';
import '../../style.css'
import { DraggableFieldExternalList } from './Draggable/DraggableFieldExternalList';

interface MyProps {
  field: any[];
  onUpdate: (updatedData: any) => void;
}

const GroupFieldServicio: React.FC<MyProps> = ({field,onUpdate}) => {

  const handleUpdateExternal = (updatedData:any) => {
    onUpdate(updatedData)
  };

  
  return (
    <>
      <DraggableFieldExternalList
        key={"DraggableFieldExternalList"}
        fieldExternal={field}
        onUpdate={(updatedData) => handleUpdateExternal(updatedData)}
        ind={1}
      />

    </>
  )
}

export default GroupFieldServicio
