import { createContext } from "react"
import { WNomina } from "./WNominaInterface"
import { Sheet,Field } from "../fichas/Sheet"


export interface FieldMigration { 
    id_campo: string,
    name:string,
    tagIntegracion:string
}

export interface SectionMigration { 
    id_seccion: string,
    name:string,
    campos:FieldMigration[]
}

export interface SheetMigration { 
    id_ficha: string,
    name:string,
    seccion:SectionMigration[]
}

export interface UpdateFieldMigration { 
    id_proceso: string,
    fichas:SheetMigration[]
}
export interface External { 
    _id:string,  
    service: string,
    api: string,
    fields:any[]
}

export const initExternalData:External = {_id:"",service:"",api:"",fields:[]}
export const initFieldMigration:FieldMigration = {id_campo:"",name:"",tagIntegracion:""}
export const initSectionMigration:SectionMigration = {id_seccion:"",name:"",campos:[]}
export const initSheetMigration:SheetMigration = {id_ficha:"",name:"",seccion:[]}
export const initUpdateFieldMigration:UpdateFieldMigration = {id_proceso: "",fichas:[]} 
export const initFieldData:Field = {
    id: '',
    name: '',
    tag: '',
    isDependent: false,
    responseType: '',
    comment: '',
    visible: true,
    editable: false,
    required: false,
    actualizable:false,
    collectionName: '',
    options: [],
    tablaData: [],
    assignedTo: '',
    dependentField: '',
    dependentFieldResponse:'',
    variable: false
}

interface PartnerContextProps {
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    wNominaData:WNomina,
    setWNominaData: (value: WNomina) => void,
    edit: boolean,
    setEdit: (value: boolean) => void,
    reloadTable: boolean,
    setReloadTable: (value: boolean) => void,
    sheet_id: string,
    setSheetId: (value: string) => void,
    idficha: string,
    setIdFicha: (value: string) => void,
    sheet: Sheet,
    setSheet: (value: Sheet) => void,
    fichaData: {label: string, value: string}[],
    setFichaData: (value: {label: string, value: string}[]) => void,
    loadingFichaContent: boolean,
    setLoadingFichaContent: (value: boolean) => void,
    updateFieldMigration: UpdateFieldMigration,
    setUpdateFieldMigration: (value: UpdateFieldMigration) => void,
    openAssignmentModal: boolean,
    setOpenAssignmentModal: (value: boolean) => void,
    fieldData: Field,
    setFieldData: (value: Field) => void,
    externalData: External,
    setExternalData: (value: External) => void,
    updateFieldAssing: boolean,
    setUpdateFieldAssing: (value: boolean) => void,
    reloadServiceExternal: boolean,
    setReloadServiceExternal: (value: boolean) => void,
    showExportPlantillaExcel: boolean,
    setShowExportPlantillaExcel: (value: boolean) => void,
    showCargarPlantillaExcel: boolean,
    setShowCargarPlantillaExcel: (value: boolean) => void,
    selectGroupName: string,
    setSelectGroupName: (value: string) => void
    
}

const PartnerContext = createContext<PartnerContextProps>({
    searchTerm: '',
    setSearchTerm: (value) => {},
    wNominaData:{_id:'',nombre: '',url: '',contenido: '',id_empresa: '',estado: true},
    setWNominaData: (value) => {},
    edit: false,
    setEdit: (value) => {},
    reloadTable: false,
    setReloadTable: (value) => {},
    sheet_id: '',
    setSheetId: (value) => {},
    idficha: '',
    setIdFicha: (value) => {},
    sheet: {
        name:"",
        type: "",
        description: "",
        sections: []
    },
    setSheet: (value) => {},
    fichaData: [],
    setFichaData: (value) => {},
    loadingFichaContent: false,
    setLoadingFichaContent: (value) => {},
    updateFieldMigration: initUpdateFieldMigration,
    setUpdateFieldMigration: (value) => {},
    openAssignmentModal: false,
    setOpenAssignmentModal: (value) => {},
    fieldData: initFieldData,
    setFieldData: (value) => {},
    externalData: initExternalData,
    setExternalData: (value) => {},
    updateFieldAssing: false,
    setUpdateFieldAssing: (value) => {},
    reloadServiceExternal: false,
    setReloadServiceExternal: (value) => {},
    showExportPlantillaExcel: false,
    setShowExportPlantillaExcel: (value) => {},
    showCargarPlantillaExcel: false,
    setShowCargarPlantillaExcel: (value) => {},
    selectGroupName: '',
    setSelectGroupName: (value) => {},
})

export {PartnerContext}