import {createContext} from 'react'
import {
  Campos,
  DataTabla,
  Ficha,
  ProcesoFichas,
  Seccion,
  Tabla,
} from '../../documentacion/MiFicha/FIchaInterface'
import {Sheet} from '../fichas/Sheet'

export type ProcessStates =
  | 'all'
  | 'procesoCerrado'
  | 'procesoCerradoConPendiente'
  | 'procesoAnulado'
export type DocumentStates = 'pending' | 'signed' | 'viewed' | 'all'
interface PartnerContextProps {
  activeOnly: ProcessStates
  state: string
  searchTerm: string
  entryDateFrom: any
  entryDateTo: any
  setActiveOnly: (value: ProcessStates) => void
  isObservacionModalOpen: boolean
  openObservacionModal: (value: string) => void
  closObservacionModal: () => void
  idRegistroFicha: string
  procesoFichaData: ProcesoFichas[]
  setProcesoFichaData: (value: ProcesoFichas[]) => void
  sheet: Sheet
  setSheet: (value: Sheet) => void
  fichas: Ficha[]
  setFichas: (value: Ficha[]) => void
  seccion: Seccion[]
  setSeccion: (value: Seccion[]) => void
  campos: Campos[]
  setCampos: (value: Campos[]) => void
  secciones: Tabla[]
  setSecciones: (value: Tabla[]) => void
  dataSecciones: DataTabla[]
  setDataSecciones: (value: DataTabla[]) => void
  handleInputObservacionChange: (value: string) => void
  setState: (value: string) => void
  setSearchTerm: (value: string) => void
  setEntryDateFrom: (value: any) => void
  setEntryDateTo: (value: any) => void
  isFichaPlantillaModalOpen: boolean
  openFichaPlantillaModal: () => void
  closeFichaPlantillaModal: () => void
  sheet_id: string
  setSheetId: (value: string) => void
  showCargaExcelModal: boolean
  setShowCargaExcelModal: (value: boolean) => void
  refresh: boolean
  setRefresh: (value: boolean) => void
  processId: string
  setProcessId: (value: string) => void
  unidadId: string
  setUnidadId: (value: string) => void
  query: Record<string, string> | null
  setQuery: (value: Record<string, string> | null) => void
  filtrosKeys: string[]
  setFiltrosKeys: (value: string[]) => void
  filtroData: {campo: string; isUnidad: boolean; values?: any[]}[]
  setFiltroData: (value: {campo: string; isUnidad: boolean; values?: any[]}[]) => void
  misUnidades: Record<string, any>[]
  setMisUnidades: (value: Record<string, any>[]) => void
  campoUnidad: string
  setCampoUnidad: (value: string) => void
  idficha: string
  setIdFicha: (value: string) => void
  fichaData: {labe: string; value: string}[]
  setFichaData: (value: {labe: string; value: string}[]) => void
  loadingFichaContent: boolean
  setLoadingFichaContent: (value: boolean) => void
  Dates: [Date | null, Date | null]
  setDates: (value: [Date | null, Date | null]) => void
  documentState: DocumentStates
  setDocumentState: (value: DocumentStates) => void
}

const PartnerContext = createContext<PartnerContextProps>({
  activeOnly: 'all',
  state: '',
  searchTerm: '',
  entryDateFrom: null,
  entryDateTo: null,
  setActiveOnly: (value) => {},
  setState: (value) => {},
  isObservacionModalOpen: false,
  openObservacionModal: (value) => {},
  closObservacionModal: () => {},
  idRegistroFicha: '',
  procesoFichaData: [],
  setProcesoFichaData: (value) => {},
  sheet: {
    name: '',
    type: '',
    description: '',
    sections: [],
  },
  setSheet: (value) => {},
  fichas: [],
  setFichas: (value) => {},
  seccion: [],
  setSeccion: (value) => {},
  campos: [],
  setCampos: (value) => {},
  secciones: [],
  setSecciones: (value) => {},
  dataSecciones: [],
  setDataSecciones: (value) => {},
  handleInputObservacionChange: (value) => {},
  setSearchTerm: (value) => {},
  setEntryDateFrom: (value: any) => {},
  setEntryDateTo: (value: any) => {},
  isFichaPlantillaModalOpen: false,
  openFichaPlantillaModal: () => {},
  closeFichaPlantillaModal: () => {},
  sheet_id: '',
  setSheetId: (value) => {},
  showCargaExcelModal: false,
  setShowCargaExcelModal: (value) => {},
  refresh: false,
  setRefresh: (value) => {},
  processId: '',
  setProcessId: (value) => {},
  unidadId: '',
  setUnidadId: (value) => {},
  query: null,
  setQuery: (value) => {},
  filtrosKeys: [],
  setFiltrosKeys: (value) => {},
  filtroData: [],
  setFiltroData: (value) => {},
  misUnidades: [],
  setMisUnidades: (value) => {},
  campoUnidad: '',
  setCampoUnidad: (value) => {},
  idficha: '',
  setIdFicha: (value) => {},
  fichaData: [],
  setFichaData: (value) => {},
  loadingFichaContent: false,
  setLoadingFichaContent: (value) => {},
  Dates: [null, null],
  setDates: (value) => {},
  documentState: 'all',
  setDocumentState: (value) => {},
})

export {PartnerContext}
